import { Box, Flex, Image, Text, Button } from '@chakra-ui/react';

const PopUpModale = ({ text, onClose }) => {
  return (
    <Box
      background={'rgba(0, 0, 0, 0.5)'}
      position={'fixed'}
      top={0}
      left={0}
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        background={'#7577E0'}
        width={{ xl: '65%', md: '65%', base: '80%' }}
        fontFamily={'arvo-regular'}
        color={'white'}
        fontSize={20}
        padding={10}
        borderRadius={10}
        boxShadow="0 10px 4px rgba(0, 0, 0, 0.1), 0 6px 8px rgba(0, 0, 0, 0.15)"
      >
        <Flex>
          <Text>{text}</Text>
          <Button onClick={onClose} variant="ghost" colorScheme="whiteAlpha">
            <Image
              height={6}
              marginTop={-10}
              width={{ xl: 14, base: 24 }}
              alt="Close"
              src="https://www.freeiconspng.com/thumbs/close-icon/close-icon-47.png"
            />
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};
export default PopUpModale;
