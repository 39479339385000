import Header from './Header';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import ViewAllServices from './ViewAllServices';
import Footer from './Footer';
import { motion } from 'framer-motion';
import { fadeIn } from '../varients';
import { useEffect, useRef, useState } from 'react';
const Editorial = () => {
  const [isInView, setIsInView] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      { threshold: 0.7 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <Box background="linear-gradient(to left, rgba(131, 101, 142, 0.32) 10%, white 80%)">
      <Header />
      <Box>
        <Flex alignItems={'center'}>
          <Image
            ml={{ xl: 20, lg: 20, md: 20, sm: 14, base: 8 }}
            height={12}
            src={require('../images/editorialImg.png')}
          />

          <Text
            textAlign={{ xl: 'left', md: 'left', base: 'center' }}
            mt={5}
            fontWeight={800}
            fontSize={30}
            fontFamily={'abhaya-libre-extrabold'}
            sx={{ textShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)' }}
            color={'#060668'}
          >
            Editorial Services
          </Text>
        </Flex>
        <Flex
          mx={{ xl: '4%', md: '6%', sm: '4%', base: '3%' }}
          flexDirection={{ xl: 'row', md: 'row', base: 'column-reverse' }}
          alignItems={'center'}
          justifyContent={'space-around'}
        >
          <Box>
          <motion.div
        ref={ref}
        variants={fadeIn('up', 0.7)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
        animate={isInView ? 'show' : 'exit'}>
            <Text
              width={{ xl: '85%' }}
              ml={5}
              fontSize={20}
              fontFamily={'arvo-regular'}
            >
              <Text as={'span'} color={'#B72323'} fontWeight={800}>
                Vidyaa Tech
              </Text>{' '}
              specialises in providing excellent editorial services that are
              customised to meet a variety of content {/* <Text> */}
              requirements. Our skilled editors can polish corporate reports,
              academic articles, and creative works to make {/* </Text> */}
              sure they are all comprehensible, captivating, and clear.
            </Text>
            </motion.div>
          </Box>
        </Flex>
      </Box>

      <Box fontFamily={'arvo-regular'} fontSize={20} mx={'5%'}>
        
        <Text>
          <Box>
          <motion.div
        ref={ref}
        variants={fadeIn('up', 0.7)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
        animate={isInView ? 'show' : 'exit'}>
            <Text
              ml={5}
              fontFamily={'arvo-regular'}
              fontSize={20}
              display={'flex'}
              flexDirection={{ xl: 'row', base: 'column' }}
            >
              
              <Flex alignItems={'center'}>
                <Box
                  mr={2}
                  bg={'#2A96CA'}
                  height={{ xl: 3, md: 4, base: 3 }}
                  width={{ xl: 3, md: 3, base: 3 }}
                  borderRadius={'50%'}
                />
                
                <Text as={'span'} color={'#2A96CA'} fontWeight={800}>
                  Content Fixing -
                </Text>{' '}
              </Flex>{' '}
              We improve the cohesion and clarity of drafts with our content
              correction service. Our aim is to enhance readability
            </Text>
            <Text mb={5} ml={5}>
              {' '}
              by tackling problems such as clumsy language, and repetition.
            </Text>
            </motion.div>
          </Box>
        </Text>
        <Box fontFamily={'arvo-regular'} fontSize={20} mx={'5%'}></Box>
        <Box>
        <motion.div
        ref={ref}
        variants={fadeIn('up', 0.7)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
        animate={isInView ? 'show' : 'exit'}>
          <Box>
            <Text
              ml={5}
              fontFamily={'arvo-regular'}
              fontSize={20}
              display={'flex'}
              flexDirection={{ xl: 'row', base: 'column' }}
            >
              <Flex alignItems={'center'}>
                <Box
                  mr={2}
                  bg={'#2A96CA'}
                  height={{ xl: 3, md: 4, base: 3 }}
                  width={{ xl: 3, md: 3, base: 3 }}
                  borderRadius={'50%'}
                />
                <Text as={'span'} color={'#2A96CA'} fontWeight={800}>
                  Proofreading -
                </Text>{' '}
              </Flex>{' '}
              The proofreading service focuses on removing flaws to ensure
              correctness and professionalism. Our crew thoroughly{' '}
            </Text>
            <Text mb={5} ml={5}>
              {' '}
              checks for grammatical, typographical, punctuation, and formatting
              flaws.
            </Text>
          </Box>
          </motion.div>

          <motion.div
        ref={ref}
        variants={fadeIn('up', 0.7)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
        animate={isInView ? 'show' : 'exit'}>
          <Box>
         
            <Text
              ml={5}
              fontFamily={'arvo-regular'}
              fontSize={20}
              display={'flex'}
              flexDirection={{ xl: 'row', base: 'column' }}
            >
              <Flex alignItems={'center'}>
                <Box
                  mr={2}
                  bg={'#2A96CA'}
                  height={{ xl: 3, md: 4, base: 3 }}
                  width={{ xl: 3, md: 3, base: 3 }}
                  borderRadius={'50%'}
                />
                <Text as={'span'} color={'#2A96CA'} fontWeight={800}>
                  Content Curating -
                </Text>{' '}
              </Flex>{' '}
              <Text>
                {' '}
                We create fresh, original content. By improving readability and
                adherence to academic conventions, Vidyaa Tech{' '}
              </Text>
            </Text>
          </Box>
          <Text mb={5} ml={5}>
            helps publishers enhance their reputation and credibility in the
            academic community.
          </Text>
          </motion.div>


          <motion.div
        ref={ref}
        variants={fadeIn('up', 0.7)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
        animate={isInView ? 'show' : 'exit'}>
          <Box>
            <Text
              ml={5}
              fontFamily={'arvo-regular'}
              fontSize={20}
              display={'flex'}
              flexDirection={{ xl: 'row', base: 'column' }}
            >
              <Flex alignItems={'center'}>
                <Box
                  mr={2}
                  bg={'#2A96CA'}
                  height={{ xl: 3, md: 4, base: 3 }}
                  width={{ xl: 3, md: 3, base: 3 }}
                  borderRadius={'50%'}
                />
                <Text as={'span'} color={'#2A96CA'} fontWeight={800}>
                  Book Indexing -
                </Text>{' '}
              </Flex>{' '}
              The book indexing service improves the usability and accessibility
              of your publications. We construct extensive
            </Text>
          </Box>
          <Text ml={5}>
            precise indexes that allow readers to swiftly access the information
            they require. Index systematically organizes the content of a book,{' '}
          </Text>
          <Text mb={5} ml={5}>
            allowing readers to swiftly locate specific topics, concepts, or
            references.
          </Text>
</motion.div>

<motion.div
        ref={ref}
        variants={fadeIn('up', 0.7)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
        animate={isInView ? 'show' : 'exit'}>
          <Box>
            <Text
              ml={5}
              fontFamily={'arvo-regular'}
              fontSize={20}
              display={'flex'}
              flexDirection={{ xl: 'row', base: 'column' }}
            >
              <Flex alignItems={'center'}>
                <Box
                  mr={2}
                  bg={'#2A96CA'}
                  height={{ xl: 3, md: 4, base: 3 }}
                  width={{ xl: 3, md: 3, base: 3 }}
                  borderRadius={'50%'}
                />
                <Text as={'span'} color={'#2A96CA'} fontWeight={800}>
                  Copy Editing -
                </Text>{' '}
              </Flex>{' '}
              Our copy editing service ensures that the material is clear,
              consistent, and engaging. We improve sentence structure,{' '}
            </Text>
          </Box>
          <Text mb={5} ml={5}>
            tone, and flow to make writing more persuasive and professional.
          </Text>
          </motion.div>
          {/* <Flex alignItems={'center'}> */}
          {/* <Box
            mr={2}
            bg={'#2A96CA'}
            height={{xl:3,md:4, base:3}}
            width={{xl:3,md:6, base:10}}
            borderRadius={'50%'}
          /> */}
          <motion.div
        ref={ref}
        variants={fadeIn('up', 0.7)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
        animate={isInView ? 'show' : 'exit'}>
          <Box>
            <Text
              ml={5}
              fontFamily={'arvo-regular'}
              fontSize={20}
              display={'flex'}
              flexDirection={{ xl: 'row', base: 'column' }}
            >
              <Flex alignItems={'center'}>
                <Box
                  mr={2}
                  bg={'#2A96CA'}
                  height={{ xl: 3, md: 4, base: 3 }}
                  width={{ xl: 3, md: 3, base: 3 }}
                  borderRadius={'50%'}
                />{' '}
                <Text as={'span'} color={'#2A96CA'} fontWeight={800}>
                  Typesetting -{' '}
                </Text>{' '}
              </Flex>{' '}
              Our typesetting service focuses on arranging text and graphics in
              a visually pleasing and readable manner. We ensure
            </Text>
          </Box>
          {/* </Flex> */}
          <Text ml={5}>
            that the documents are professionally formatted, with uniform fonts,
            spacing, and alignment. This service is essential for any{' '}
          </Text>
          <Text ml={5}>
            {' '}
            organization that creates printed or digital products like books,
            brochures, reports, and manuals.
          </Text>
          </motion.div>
        </Box>
      </Box>
      <ViewAllServices />
      <Footer />
    </Box>
  );
};
export default Editorial;
