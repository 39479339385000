import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DropDownServices from './DropDownServices';

const Screens = [
  { screen: 'home', title: 'Home', navigate: '/' },
  { screen: 'about', title: 'About', navigate: '/about' },
  { screen: 'services', title: 'Services', navigate: '/services' },
  { screen: 'career', title: 'Career', navigate: '/career' },
];
const Header = () => {
  const [isHovered, setIsHovered] = useState(null);
  const currentRoute = useLocation();
  const navigate = useNavigate();
  return (
    <Box>
      <Flex
        bg={'red'}
        flexWrap={'wrap'}
        // margin={5}
        justifyContent={'space-between'}
        px={'2'}
        py={'1'}
        // width={'100%'}
        alignItems={'center'}
        background="linear-gradient( to left, rgba(131, 101, 142, 0.32) 10%, white 80%)"
      >
        <Image
          src={require('../images/vidyaLogo.png')}
          margin={5}
          cursor={'pointer'}
          onClick={()=> navigate('/')}
          // resize={'contain'}
          width={150}
          height={50}
        />
        {/*<Text
          borderRadius={5}
          // justifyContent={'center'}
          // alignItems={'center'}
          bg={'#12129B'}
          color={'white'}
          width={100}
          // height={'6%'}
          textAlign={'center'}
          py={1}
          fontFamily={'libreFranklin'}
          fontSize={15}
          cursor={'pointer'}
          marginRight={3}
        >
          {/* Log in */}
        {/* </Text>*/}
      </Flex>

      <Flex
        flexWrap={'wrap'}
        bg={'white'}
        justifyContent={'space-around'}
        py={3}
        width={'100%'}
      >
        {Screens.map((items, index) => {
          return (
            <Box
              // fontFamily={'libreFranklin'}
              // fontFamily={'abhaya-libre-extrabold'}
              fontFamily={'arvo-bold'}
              fontSize={20}
              key={index}
              color={
                currentRoute.pathname === items.navigate
                  ? 'rgba(183, 35, 35, 1)'
                  : 'rgba(18, 18, 155, 1)'
              }
              position={'relative'}
              _hover={{ color: 'rgba(183, 35, 35, 1)' }}
              cursor={'pointer'}
              onMouseEnter={() => setIsHovered(index)}
              onMouseLeave={() => setIsHovered(null)}
              onClick={() => navigate(items.navigate)}
            >
              <Box fontWeight={800}>
                {items.title}
                {isHovered === index && index === 2 && (
                 <DropDownServices/>
                )}
              </Box>
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
};
export default Header;
