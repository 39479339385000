import { Box, Flex, Image, Text, useMediaQuery } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

const LearningSolution = ({
  image,
  heading,
  text,
  content,
  padding,
  index,
}) => {
  const flexDirection = index % 2 === 0 ? 'row' : 'row-reverse';

  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  return (
    <Box>
      <Box>
        <Text
          color={'#010155'}
          fontFamily={'ar'}
          background="#957E9D"
          fontSize={{ xl: 40, base: 28 }}
          fontWeight={700}
          padding={padding}
          paddingX={{ xl: 32, md: 28 }}
          sx={{ textShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)' }}
        >
          {heading}
        </Text>
      </Box>

      <Flex
        flexDirection={isLargerThan768 ? flexDirection : 'column-reverse'}
        justifyContent={'space-between'}
        paddingX={isLargerThan768 ? 20 : 5}
        paddingY={8}
        alignItems="center"
        background={'#D2CADB'}
        gap={8}  // Adding gap to ensure consistent space between text and image
      >
        <Box flex="1" paddingX={isLargerThan768 ? 10 : 5}>
          <Text fontWeight={'bold'} fontFamily={'arvo-regular'} fontSize={20}>
            {text}
          </Text>
          <Text
            fontFamily={'arvo-regular'}
            marginY={4}
            width={'100%'}
          >
            {content}
          </Text>
        </Box>

        <Box flex="1" display="flex" justifyContent="center">
          <Image
            height={isLargerThan768 ? '280px' : '200px'}
            width="65%"
            borderRadius={10}
            alt="Learning Solution Image"
            src={image}
            marginTop={isLargerThan768 ? -20 : 5}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default LearningSolution;
