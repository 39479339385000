import Header from './Header';
import { Box, Flex, Grid, Image, Text } from '@chakra-ui/react';
import ViewAllServices from './ViewAllServices';
import Footer from './Footer';
import AnimationPageBoxes from './AnimationPageBoxes';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { fadeIn } from '../varients';

const Animation = () => {
  
    const [isInView, setIsInView] = useState(false);
  const ref = useRef(null);
      useEffect(() => {
        const observer = new IntersectionObserver(
          ([entry]) => {
            setIsInView(entry.isIntersecting);
          },
          { threshold: 0.7 } 
        );
    
        if (ref.current) {
          observer.observe(ref.current);
        }
    
        return () => {
          if (ref.current) {
            observer.unobserve(ref.current);
          }
        };
      }, []);
  const images = [
    {
      id: 1,
      image: require('../images/animations.png'),
      text: 'We create 2D and 3D animations that simplify complex ideas into clear, engaging  visuals, boosting student retention and interaction. Built with HTML5, our animations ensure high-quality, responsive content for various devices and platforms. ',
    },
    {
      id: 2,
      image: require('../images/clarityContentImg.png'),
      text: 'We focus on clarity in every project, ensuring that visuals and messages are easy to understand and aligned with learning objectives enhancing content retention and comprehension. ',
    },
    {
      id: 3,
      image: require('../images/videoSolutionImg.png'),
      text: 'Our video solutions include explainer videos and instructional series, designed to communicate educational content effectively. We incorporate clear voiceovers and background music to enhance engagement and storytelling.',
    },
    {
      id: 4,
      image: require('../images/typingMan.png'),
      text: 'Our team supports all animations and videos with precise, professional text, including captions, subtitles, and on-screen content, ensuring accessibility and accuracy.',
    },
    {
      id: 5,
      image: require('../images/illustrationImg.png'),
      text: 'We create detailed storyboards, artworks, and illustrations, including custom characters, to  visualize animations and videos. Our artistic compositions ensure  that the final product is aligned with educational goals and visually engaging.',
    },
    {
      id: 6,
      image: require('../images/virtualLearningImg.png'),
      text: 'We design immersive virtual environments with animation to offer interactive learning experiences for complex subjects. Our solutions help publishers create engaging digital content that elevates educational materials.',
    },
  ];

  return (
    <Box background="linear-gradient( to left, rgba(131, 101, 142, 0.32) 10%, white 80%)">
      <Header />
      <motion.div ref={ref}
                  variants={fadeIn('up', 0.7)}
                  initial="hidden"
                  whileInView={'show'}
                  viewport={{ once: false, amount: 0.3 }}
                  animate={isInView ? 'show' : 'exit'}>
        <Flex marginX={16} marginY={10}>
          <Image
            marginX={3}
            height={10}
            alt=""
            src={require('../images/animation 2 icon 1.png')}
          />
          <Text
            color={'#060668'}
            fontFamily={'abhaya-libre-extrabold'}
            fontSize={30}
          >
            Animations Services
          </Text>
        </Flex>
        <Text
          fontFamily={'arvo-regular'}
          fontSize={18}
          // fontWeight={'bold'}
          marginX={16}
        >
          At{' '}
          <Text as="span" color={'#7577E6'} fontWeight={700}>
            Vidyaa Tech,{' '}
          </Text>{' '}
          we specialize in creating impactful animations content designed to
          enhance learning experiences and drive engagement. Our solutions are
          crafted to support educational initiatives, delivering clear,
          compelling visuals that simplify complex concepts and foster deeper
          understanding
        </Text>
      </motion.div>
    
      <motion.div ref={ref}
            variants={fadeIn('up', 0.7)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            animate={isInView ? 'show' : 'exit'}> 
      <Grid
        marginX={{ xl: 20, md: 20, sm: 32, base: 10 }}
        templateColumns={{
          xl: 'repeat(3, 1fr)',
          lg: 'repeat(3, 1fr)',
          md: 'repeat(2, 1fr)',
          base: 'repeat(1, 1fr)',
        }}
      >
        {images.map(image => (
          <AnimationPageBoxes image={image}  />
        ))}
      </Grid>
      
      </motion.div>
      <ViewAllServices />
      <Footer />
    </Box>
  );
};
export default Animation;
