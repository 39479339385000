// import { useNavigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import { Box, Flex, Grid, Image, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { fadeIn } from '../varients';
import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

const Service = [
  {
    img: require('../images/learningWithBg.png'),
    id: 'e-learning',
    route: 'learning',
    height: { xl: 190, lg: 190, md: 160, base: '220' },
  },
  {
    img: require('../images/editorialWithBg.png'),
    id: 'editorial',
    route: 'editorial',
    height: { xl: 190, lg: 190, md: 160, base: '220' },
  },
  {
    img: require('../images/alignmentWithBg.png'),
    id: 'alignment',
    route: 'alignment',
    height: { xl: 190, lg: 190, md: 160, base: '220' },
  },
  {
    img: require('../images/animationWithBg.png'),
    id: 'translation',
    route: 'animation',
    height: { xl: 190, lg: 190, md: 160, base: '220' },
  },
  {
    img: require('../images/authoringWithBg.png'),
    id: 'authoring',
    route: 'authoring',
    height: { xl: 215, lg: 190, md: 160, base: '220' },
  },
  {
    img: require('../images/tutoringWithBg.png'),
    id: 'tutoring',
    route: 'tutoring',
    height: { xl: 200, lg: 190, md: 160, base: '220' },
  },
  {
    img: require('../images/translationWithBg.png'),
    id: 'translation',
    route: 'translation',
    height: { xl: 200, lg: 190, md: 160, base: '220' },
  },
];

const Services = () => {
  const navigate = useNavigate();
  const handleClick = service => {
    navigate(`/services/${service}`);
    console.log('clciked', service);
  };

  const isDesktop = useMediaQuery({ minWidth: 1024 });

  const connect = () => {
    navigate('/letsconnect');
  };
  const MotionBox = motion(Box);
  const [isInView, setIsInView] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      { threshold: 0.7 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <Box background="linear-gradient( to left, rgba(131, 101, 142, 0.32) 10%, white 80%)">
      <Header />
      <Flex
        flexDirection={{ xl: 'row', lg: 'row', md: 'row', base: 'column' }}
        justifyContent={'space-around'}
        margin={20}
      >
        {/* Title and Image Section */}
        <MotionBox
          variants={fadeIn('right', 0.7)}
          initial="hidden"
          whileInView="show"
          exit={isDesktop ? 'exit' : false}
          viewport={{ once: false, amount: 0.3 }}
          ref={ref}
          animate={isInView ? 'show' : 'exit'}
          width={{ xl: '40%', lg: '40%', md: '50%', base: '100%' }} // Adjust width for responsiveness
          textAlign={{ base: 'center', lg: 'left' }}
        >
          <Text
            color={'#060668'}
            fontSize={'40'}
            fontWeight={700}
            fontFamily={'abhaya-libre-extrabold'}
            sx={{ textShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)' }}
          >
            OUR SERVICES
          </Text>
          <Image
            display={{ xl: 'block', lg: 'block', md: 'block', base: 'none' }} // Hide image on small screens
            width={{ xl: 490, lg: 320, md: 370 }}
            height={{ xl: 410, lg: 240 }}
            src={require('../images/servicesSideImg.png')}
          />
        </MotionBox>
        <div>
          {/* First Grid (First row with 4 items) */}
          <Grid
            templateColumns={{
              xl: 'repeat(4, 1fr)', // 4 items per row for extra large screens
              lg: 'repeat(4, 1fr)', // 4 items per row for large screens
              md: 'repeat(2, 1fr)', // 3 items per row for medium screens
              base: 'repeat(1, 1fr)', // 2 items per row for smaller screens
            }}
            gap={6} // Gap between items
            width="100%"
            marginTop={{ base: 10, xl: 0 }}
          >
            {Service.slice(0, 4).map((item, index) => {
              // Conditionally set marginX based on the index being even or odd
              const marginX = index % 2 === 0 ? 10 : 0;

              return (
                <motion.div
                  key={index}
                  variants={fadeIn('left', 0.7)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: false, amount: 0.3 }}
                  animate={isInView ? 'show' : 'exit'}
                >
                  <Image
                    mx={marginX} // Apply conditional marginX based on index
                    src={item.img}
                    // objectFit="cover"
                    onClick={() => {
                      const getElementId = document.getElementById(item.id);
                      getElementId.scrollIntoView({ behavior: 'smooth' });
                    }}
                    margin={{ md: 2, base: 5 }}
                    marginBottom={{ base: 10 }}
                    marginX={{ sm: 20 }}
                    height={item.height}
                    cursor="pointer"
                  />
                </motion.div>
              );
            })}
          </Grid>

          {/* Second Grid (Second row with 3 items) */}
          <Grid
            templateColumns={{
              xl: 'repeat(3, 1fr)', // 3 items per row for extra large screens
              lg: 'repeat(3, 1fr)', // 3 items per row for large screens
              md: 'repeat(2, 1fr)', // 3 items per row for medium screens
              base: 'repeat(1, 1fr)', // 2 items per row for smaller screens
            }}
            // gap={} // Gap between items
            width={{ xl: '90%', base: '100%' }}
            marginTop={{ base: 10, xl: 0 }}
            marginX={{ xl: 10 }}
          >
            {Service.slice(4, 7).map((item, index) => {
              // Conditionally set marginX based on the index being even or odd
              const marginX = index % 2 === 0 ? 10 : 0;

              return (
                <motion.div
                  key={index}
                  variants={fadeIn('left', 0.7)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: false, amount: 0.3 }}
                  animate={isInView ? 'show' : 'exit'}
                >
                  <Image
                    mx={marginX} // Apply conditional marginX based on index
                    src={item.img}
                    objectFit="cover"
                    onClick={() => {
                      const getElementId = document.getElementById(item.id);
                      getElementId.scrollIntoView({ behavior: 'smooth' });
                    }}
                    margin={{ md: 2, base: 5 }}
                    marginBottom={{ base: 10 }}
                    height={item.height}
                    cursor="pointer"
                  />
                </motion.div>
              );
            })}
          </Grid>
        </div>
      </Flex>
      <motion.div
        ref={ref}
        variants={fadeIn('up', 0.7)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
        animate={isInView ? 'show' : 'exit'}
      >
        <Box padding={5} background={'rgba(212,203,220,255)'} id="e-learning">
          <Box width={{ xl: '60%', lg: '65%', md: '80%', base: '100%' }}>
            <Text
              paddingTop={7}
              fontWeight={800}
              fontSize={32}
              textAlign={'center'}
              color={'#010155'}
              fontFamily={'abhaya-libre-extrabold'}
            >
              E-learning Customization
            </Text>
          </Box>
          <Flex
            mt={{ xl: -10, lg: -10 }}
            flexDirection={{
              xl: 'row',
              lg: 'row',
              md: 'row',
              base: 'column-reverse',
            }}
            justifyContent={'space-around'}
            alignItems={'center'}
          >
            <Box>
              <Text
                my={10}
                fontSize={18}
                textAlign={'left'}
                fontFamily={'arvo-regular'}
              >
                <Text>
                  We provides E-Learning Customization Services referring to a
                  range of specialized solutions
                </Text>
                <Text>
                  designed to adapt and tailor digital learning experiences to
                  meet the specific needs of learners.
                </Text>
                <Text>
                  These services aim to transform traditional educational
                  methods into dynamic, engaging, and{' '}
                </Text>
                effective online learning environments.
              </Text>
              <Box
                display={'flex'}
                justifyContent={'center'}
                width={{ xl: '80%', lg: '80%' }}
              >
                <Image
                  height={58}
                  width={170}
                  alignContent={'center'}
                  marginTop={{ base: 5 }}
                  src={require('../images/learMoreBtn (1).png')}
                  cursor={'pointer'}
                  _hover={{
                    transform: 'scale(1.1)',
                    transition: 'all 0.2s ease-in-out',
                  }}
                  onClick={() => handleClick('learning')}
                />
              </Box>
            </Box>
            <Box>
              <Image
                width={220}
                height={220}
                mb={{ xl: '50%', lg: '48%', md: '45%' }}
                src={require('../images/learnCustomImage.png')}
              />
            </Box>
          </Flex>
        </Box>
      </motion.div>

      <motion.div
        variants={fadeIn('up', 0.7)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
      >
        <Box
          paddingY={9}
          marginY={20}
          background={'rgba(212,203,220,255)'}
          id="editorial"
        >
          <Box
            width={{ xl: '64%', lg: '70%', md: '82%', base: '100%' }}
            mb={10}
          >
            <Text
              paddingTop={7}
              fontWeight={800}
              fontSize={32}
              textAlign={{
                xl: 'right',
                lg: 'right',
                md: 'right',
                base: 'center',
              }}
              marginBottom={{ xl: '-2%', lg: '-2%', md: '-2%', base: 10 }}
              color={'#010155'}
              fontFamily={'abhaya-libre-extrabold'}
            >
              Editorial Services
            </Text>
          </Box>
          <Flex
            flexDirection={{ xl: 'row', lg: 'row', md: 'row', base: 'column' }}
            marginX={10}
            justifyContent={'space-around'}
            alignItems={'center'}
          >
            <Image
              marginTop={-20}
              height={220}
              mt={{ xl: '-7%', base: '-6%' }}
              mb={2}
              src={require('../images/editorialServicesImg.png')}
            />
            <Box>
              <Text
                fontSize={18}
                textAlign={'left'}
                fontFamily={'arvo-regular'}
              >
                {' '}
                <Text>
                  We offer comprehensive editorial services to ensure your
                  educational content is accurate, clear, and engaging.{' '}
                </Text>
                Our team of experienced editors works meticulously to polish
                your materials to the highest standards.
              </Text>
              <Box
                display={'flex'}
                justifyContent={'center'}
                width={{ xl: '80%', lg: '80%' }}
              >
                <Image
                  height={58}
                  width={170}
                  marginTop={{ xl: 10, base: 5 }}
                  src={require('../images/learMoreBtn (1).png')}
                  cursor={'pointer'}
                  _hover={{
                    transform: 'scale(1.1)',
                    transition: 'all 0.2s ease-in-out',
                  }}
                  onClick={() => handleClick('editorial')}
                />
              </Box>
            </Box>
          </Flex>
        </Box>
      </motion.div>

      <motion.div
        ref={ref}
        variants={fadeIn('up', 0.7)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
        animate={isInView ? 'show' : 'exit'}
      >
        <Box padding={5} background={'rgba(212,203,220,255)'} id="tutoring">
          <Box width={{ xl: '60%', lg: '65%', md: '80%', base: '100%' }}>
            <Text
              paddingTop={7}
              fontWeight={800}
              fontSize={32}
              textAlign={'center'}
              color={'#010155'}
              fontFamily={'abhaya-libre-extrabold'}
            >
              Tutoring
            </Text>
          </Box>
          <Flex
            flexDirection={{
              xl: 'row',
              lg: 'row',
              md: 'row',
              base: 'column-reverse',
            }}
            alignItems={'center'}
            textAlign={'center'}
            justifyContent={'space-around'}
            // marginX={'5%'}
          >
            <Box>
              <Text
                fontSize={18}
                mt={{ xl: 10, lg: 10, md: 10 }}
                textAlign={'left'}
                fontFamily={'arvo-regular'}
              >
                <Text>
                  We offer personalized tutoring services to support learners at
                  various levels. Our qualified tutors provide one-on-one and
                </Text>
                <Text>
                  group sessions to help students achieve their academic goals.
                </Text>
              </Text>
              <Box
                mt={10}
                display={'flex'}
                justifyContent={'center'}
                width={{ xl: '75%', lg: '85%', md: '100%' }}
              >
                <Image
                  height={58}
                  width={170}
                  src={require('../images/learMoreBtn (1).png')}
                  cursor={'pointer'}
                  _hover={{
                    transform: 'scale(1.1)',
                    transition: 'all 0.2s ease-in-out',
                  }}
                  onClick={() => handleClick('tutoring')}
                />
              </Box>
            </Box>
            <Box>
              <Image
                // width={20}
                height={240}
                mt={{ xl: '-50%', lg: '-48%', md: '-52%' }}
                src={require('../images/tutoringImg.png')}
              />
            </Box>
          </Flex>
        </Box>
      </motion.div>

      <motion.div
        ref={ref}
        variants={fadeIn('up', 0.7)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
        animate={isInView ? 'show' : 'exit'}
      >
        <Box
          paddingY={9}
          marginY={20}
          background={'rgba(212,203,220,255)'}
          id="authoring"
        >
          <Box width={{ xl: '60%', lg: '65%', md: '80%', base: '100%' }}>
            <Text
              textAlign={{
                xl: 'right',
                lg: 'right',
                md: 'right',
                base: 'center',
              }}
              paddingTop={7}
              fontWeight={800}
              fontSize={32}
              mb={{ xl: 0, lg: 1, base: 10 }}
              color={'#010155'}
              fontFamily={'abhaya-libre-extrabold'}
            >
              Authoring Services
            </Text>
          </Box>
          <Flex
            flexDirection={{ xl: 'row', lg: 'row', md: 'row', base: 'column' }}
            marginX={{ xl: 10, lg: 10, base: 6 }}
            justifyContent={'space-around'}
            alignItems={'center'}
          >
            <Image
              marginTop={-10}
              mb={2}
              height={240}
              src={require('../images/authoringImg.png')}
            />
            <Box>
              <Text
                fontSize={18}
                textAlign={'left'}
                fontFamily={'arvo-regular'}
              >
                {' '}
                <Text>
                  Our authoring services help you create high-quality
                  educational content from scratch. Our team of skilled authors
                  and{' '}
                </Text>
                subject matter experts collaborate with you to develop engaging
                and informative materials tailored to your needs.
              </Text>
              <Box
                display={'flex'}
                justifyContent={'center'}
                width={{ xl: '75%', lg: '85%', md: '100%' }}
              >
                <Image
                  height={58}
                  width={170}
                  // marginLeft={{ xl: '58%', lg: '59%', md: '58%', base: '29%' }
                  marginTop={{ base: 8 }}
                  src={require('../images/learMoreBtn (1).png')}
                  cursor={'pointer'}
                  _hover={{
                    transform: 'scale(1.1)',
                    transition: 'all 0.2s ease-in-out',
                  }}
                  onClick={() => handleClick('authoring')}
                />
              </Box>
            </Box>
          </Flex>
        </Box>
      </motion.div>

      <motion.div
        ref={ref}
        variants={fadeIn('up', 0.7)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
        animate={isInView ? 'show' : 'exit'}
      >
        <Box padding={5} background={'rgba(212,203,220,255)'} id="alignment">
          <Box width={{ xl: '60%', lg: '65%', md: '80%', base: '100%' }}>
            <Text
              paddingTop={7}
              fontWeight={800}
              fontSize={32}
              textAlign={'center'}
              color={'#010155'}
              fontFamily={'abhaya-libre-extrabold'}
              mb={{ xl: -10 }}
            >
              Alignment Services
            </Text>
          </Box>
          <Flex
            flexDirection={{
              xl: 'row',
              lg: 'row',
              md: 'row',
              base: 'column-reverse',
            }}
            alignItems={'center'}
            textAlign={'center'}
            justifyContent={'space-around'}
            // marginX={'5%'}
          >
            <Box>
              <Text
                mt={10}
                fontSize={18}
                textAlign={'left'}
                fontFamily={'arvo-regular'}
              >
                <Text>
                  Our alignment services ensure that your educational content is
                  consistent with curriculum standards and learning objectives.
                </Text>
                <Text>
                  Our expert team works closely with you to align your content
                  with the specific goals of your educational programs.
                </Text>
              </Text>
              <Box
                display={'flex'}
                justifyContent={'center'}
                width={{ xl: '73%', lg: '80%', md: '100%', base: '100%' }}
              >
                <Image
                  height={58}
                  width={170}
                  marginTop={{ base: 8 }}
                  src={require('../images/learMoreBtn (1).png')}
                  cursor={'pointer'}
                  _hover={{
                    transform: 'scale(1.1)',
                    transition: 'all 0.2s ease-in-out',
                  }}
                  onClick={() => handleClick('alignment')}
                />
              </Box>
            </Box>
            <Image
              width={220}
              height={220}
              src={require('../images/alignmentImg.png')}
            />
          </Flex>
        </Box>
      </motion.div>

      <motion.div
        ref={ref}
        variants={fadeIn('up', 0.3)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.7 }}
        animate={isInView ? 'show' : 'exit'}
      >
        <Box
          paddingY={9}
          marginY={20}
          background={'rgba(212,203,220,255)'}
          id="translation"
        >
          <Box
            width={{ xl: '65%', lg: '73%', md: '84%', base: '100%' }}
            mb={10}
          >
            <Text
              textAlign={{
                xl: 'right',
                lg: 'right',
                md: 'right',
                base: 'center',
              }}
              mb={{ xl: -14, lg: -10, md: 4, base: 20 }}
              paddingTop={7}
              fontWeight={800}
              fontSize={32}
              color={'#010155'}
              fontFamily={'abhaya-libre-extrabold'}
            >
              Translation Services
            </Text>
          </Box>
          <Flex
            flexDirection={{ xl: 'row', lg: 'row', md: 'row', base: 'column' }}
            marginX={10}
            justifyContent={'space-around'}
            alignItems={'center'}
          >
            <Image
              marginTop={-20}
              height={230}
              src={require('../images/translationImg.png')}
            />
            <Box>
              <Text
                fontSize={18}
                textAlign={'left'}
                fontFamily={'arvo-regular'}
              >
                {' '}
                <Text>
                  We provide professional translation services to make your
                  educational content accessible to a global audience.{' '}
                </Text>
                Our expert translators ensure that your materials retain their
                accuracy and impact across different languages.
              </Text>
              <Box
                display={'flex'}
                justifyContent={'center'}
                width={{ xl: '80%', lg: '78%', md: '80%', base: '100%' }}
              >
                <Image
                  height={58}
                  width={170}
                  // marginLeft={{ xl: '58%', lg: '59%', md: '58%', base: '29%' }}
                  marginTop={{ base: 5 }}
                  src={require('../images/learMoreBtn (1).png')}
                  cursor={'pointer'}
                  _hover={{
                    transform: 'scale(1.1)',
                    transition: 'all 0.2s ease-in-out',
                  }}
                  onClick={() => handleClick('translation')}
                />
              </Box>
            </Box>
          </Flex>
        </Box>
      </motion.div>

      <motion.div
        ref={ref}
        variants={fadeIn('up', 0.7)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
        animate={isInView ? 'show' : 'exit'}
      >
        <Box
          height={{ xl: '400px' }}
          padding={5}
          background={'rgba(212,203,220,255)'}
          id="animation"
        >
          <Box width={{ xl: '60%', lg: '65%', md: '80%', base: '100%' }}>
            <Text
              paddingTop={7}
              fontWeight={800}
              fontSize={32}
              textAlign={'center'}
              color={'#010155'}
              fontFamily={'abhaya-libre-extrabold'}
              mb={{ xl: -10 }}
            >
              Animation Services
            </Text>
          </Box>
          <Flex
            flexDirection={{
              xl: 'row',
              lg: 'row',
              md: 'row',
              base: 'column-reverse',
            }}
            alignItems={'center'}
            textAlign={'center'}
            justifyContent={'space-around'}
            marginX={'5%'}
          >
            <Box>
              <Text
                mt={10}
                fontSize={18}
                textAlign={'left'}
                fontFamily={'arvo-regular'}
              >
                <Text>
                  We provide professional animation services that make your
                  educational content visually engaging and easier to
                  understand.
                </Text>
                <Text>
                  Our expert animators craft clear, impactful visuals that
                  effectively convey complex concepts, ensuring your materials
                  resonate with learners of all backgrounds.
                </Text>
              </Text>
              <Box
                display={'flex'}
                justifyContent={'center'}
                width={{ xl: '73%', lg: '80%', md: '100%', base: '100%' }}
              >
                <Image
                  height={58}
                  width={170}
                  marginTop={{ base: 8 }}
                  src={require('../images/learMoreBtn (1).png')}
                  cursor={'pointer'}
                  _hover={{
                    transform: 'scale(1.1)',
                    transition: 'all 0.2s ease-in-out',
                  }}
                  onClick={() => handleClick('animation')}
                />
              </Box>
            </Box>
            <Image
              width={220}
              height={220}
              src={require('../images/animationImage.png')}
            />
          </Flex>
        </Box>
      </motion.div>

      <Box
        marginTop={-20}
        background={
          'conic-gradient(from 35.64deg at -31.94% 88.73%, #F8F8F8 0deg, #F9F9F9 0.04deg, #786779 45deg, #EDCFED 66.73deg, #CBA9A9 75.6deg, #FFD1D1 75.64deg, #EFFFFF 140.4deg, #F9F9F9 310.43deg, #F8F8F8 360deg)'
        }
      >
        <Flex alignItems={'center'} justifyContent={'space-around'}>
          <Box
            margin={10}
            // css={{
            //   WebkitTextStroke: '1px #F3616199', // Outline color and width (red)
            // }}
            sx={{ textShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)' }}
          >
            <Text
              fontFamily={'abhaya-libre-extrabold'}
              fontWeight={700}
              fontSize={38}
            >
              <Text>Work</Text>
              <Text>with us!</Text>
            </Text>
            <Image
              width={190}
              marginY={10}
              height={58}
              cursor={'pointer'}
              src={require('../images/letsConnectBlue.png')}
              onClick={connect}
              _hover={{
                transform: 'scale(1.1)',
                transition: 'all 0.2s ease-in-out',
              }}
            />
          </Box>
          <Image
            display={{ xl: 'block', base: 'none' }}
            height={410}
            width={530}
            src={require('../images/handShake.png')}
          />
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
};
export default Services;
