import Header from './Header';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import ViewAllServices from './ViewAllServices';
import Footer from './Footer';
import { useEffect, useRef, useState } from 'react';
import { motion, useTransform, useViewportScroll } from 'framer-motion';
import { fadeIn } from '../varients';
          const lists1 = [
            {
              content: 'Personalized Consultation',
            },
            {
              content: 'Expert Tutor Matching',
            },
            {
              content: 'Engaging Sessions',
            },
            {
              content: 'Progress Monitoring and Feedback',
            },
            {
              content: 'Flexible Accessibility',
            },
            {
              content: 'Personalized Approach',
            },
          ];
          const lists2 = [
            {
              content: 'Qualified Tutors',
            },
            {
              content: 'Interactive Learning.',
            },
            {
              content: ' Flexibility and Convenience',
            },
            {
              content: 'Proven Results Tutoring',
            },
            {
              content: ' Supportive Learning Environment',
            },
            {
              content: 'Commitment to Excellence',
            },
          ];
const Tutoring = () => {
  const [isInView, setIsInView] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      { threshold: 0.7 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);
  return (
    <Box
      background="linear-gradient(to left, rgba(131, 101, 142, 0.32) 10%, white 80%)"
      minHeight="100vh"
    >
      <Header />
      <Box>
        <Flex alignItems={'center'}>
          <Image
            ml={{ xl: 20, md: 20, sm: 20, base: 20 }}
            src={require('../images/tutoringImgg.png')}
            height={10}
          />
          <Text
            // textAlign={{xl:'left', md:'left', base:'center'}}
            fontWeight={800}
            fontSize={30}
            fontFamily={'abhaya-libre-extrabold'}
            color={'#060668'}
            ml={2}
            mb={5}
            mt={6}
          >
            Tutoring Services
          </Text>
        </Flex>
         <motion.div
        ref={ref}
        variants={fadeIn('up', 0.7)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
        animate={isInView ? 'show' : 'exit'}>
        <Box ml={20} fontFamily={'arvo-regular'} fontSize={20} width="65%">
          <Text width={{ xl: '80%' }}>
            At
            <Text as={'span'} color={'#B72323'} fontWeight={800}>
              {' '}
              Vidyaa Tech,
            </Text>{' '}
            we’re passionate about transforming education through innovative
            tutoring services.
          </Text>
          {/* </Text> */}
          <Text width={{ xl: '88%' }} mt={2}>
            Our mission is to empower students of all ages with the knowledge
            and skills they need
            {/* </Text> */}
            {/* <Text> */} to succeed. Through personalized one-on-one sessions
            delivered by expert tutors, we {/* </Text> */}
            {/* <Text> */}
            ensure that each student receives tailored support to meet their
            unique learning goals.
            {/* </Text> */}
          </Text>
        </Box>
        </motion.div>
        <motion.div
        ref={ref}
        variants={fadeIn('up', 0.7)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
        animate={isInView ? 'show' : 'exit'}>
        <Flex
          pl={{ xl: 0, md: 0, base: 8 }}
          flexDirection={{ xl: 'row', md: 'row', base: 'column' }}
          width={{ xl: '68%', md: '90%', base: '100%' }}
          justifyContent={'space-between'}
          mt={'5%'}
          mx={'6%'}
        >
          <Flex flexDirection={'column'}>
            {lists1.map(list => (
              <Flex alignItems={'center'}>
                <Image
                  mr={2}
                  height={3}
                  src={require('../images/darkDot.png')}
                />
                <Text fontFamily={'arvo-regular'} fontWeight={400}>
                  {list.content}
                </Text>
              </Flex>
            ))}
          </Flex>
          <Flex flexDirection={'column'}>
            {lists2.map(list => (
              <Flex alignItems={'center'}>
                <Image
                  mr={2}
                  height={3}
                  src={require('../images/darkDot.png')}
                />
                <Text fontFamily={'arvo-regular'} fontWeight={400}>
                  {list.content}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
        /</motion.div>
        <ViewAllServices />
        <Footer />
      </Box>
    </Box>
  );
};

export default Tutoring;
