import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import Header from './Header';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import { motion, useTransform, useViewportScroll } from 'framer-motion';
import { fadeIn } from '../varients';
import { useEffect, useRef, useState } from 'react';
import LearningSolution from './LearningSolutions';
const AnimatedText = motion(Text);
const MotionBox = motion(Box);
const Services = [
  {
    // title: 'E-Learning Customization',
    image: require('../images/eLearningGroupedImg.png'),
    navigator: '/services/learning',
  },
  
  {
    // title: 'Editorial',
    image: require('../images/editorialGrpImg.png'),
    navigator: '/services/editorial',
  },
  {
    // title: 'Alignment',
    image: require('../images/alignmentGrpImg.png'),
    navigator: '/services/alignment',
  },
  {
    image: require('../images/animationGroupImg.png'),
    navigator: '/services/animation',
  },
  {
    // title: 'Authoring',
    image: require('../images/authoringGrpImg.png'),
    navigator: '/services/authoring',
  },
  {
    // title: 'Tutoring',
    image: require('../images/tutoringGroupImg.png'),
    navigator: '/services/tutoring',
  },
  {
    // title: 'Translation',
    image: require('../images/translationGrpImg.png'),
    navigator: '/services/translation',
  },
];

const Determination = [
  {
    heading: 'OUR IDEOLOGY',
    text: 'We believe that education should be accessible, engaging, and tailored to the unique needs of each learner. Our mission is to harness cutting-edge technology to create innovative solutions that empower students and educators alike. By integrating interactive tools, personalized learning experiences, we aim to make learning inclusive.',
  },
  {
    heading: 'OUR COMMITMENTS',
    text: 'We are committed to providing exceptional service and support, ensuring that our solutions seamlessly integrate into educational frameworks. We value open communication and collaboration, working closely with our clients to understand their goals and challenges, and delivering solutions that drive success.  ',
  },
  {
    heading: 'OUR OBLIGATIONS',
    text: 'We are dedicated to ethical practices, transparency, and building lasting relationships based on trust and mutual respect. Our obligation extends to providing ongoing support and training, ensuring that both learners and educators can fully leverage our tools and resources. ',
  },
];

const Partners = [
  
  {
    image: require('../images/carnegLogo.png'),
    width:'150px'
  },
  
  {
    image: require('../images/cheggLogo.png'),
    width:'178px'
  },
  
  {
    image: require('../images/bookNowLogo.png'),
    width:'220px'
  },
  
  {
    image: require('../images/byjuLogo.png'),
    width:'160px'
  },
  
  {
    image: require('../images/ohioLogo.png'),
    width:'150px'
  },
  
  {
    image: require('../images/isxRedLogo.png'),
    width:'220px'
  },
  
  {
    image: require('../images/floridaLogo.png'),
    width:'170px'
  },
  
  {
    image: require('../images/bartleLogo.png'),
    width:'195px'
  },
  
  {
    image: require('../images/learnLogo.png'),
    width:'220px'
  },
  
  {
    image: require('../images/wileylogo.png'),
    width:'180px'
  },
  
  {
    image: require('../images/mpsLogo.png'),
    width:'120px'
  },
  
  {
    image: require('../images/khanLogo.png'),
    width:'230px'
  },
  
  {
    image: require('../images/vedantuLogo (1).png'),
    width:'180px'
  },
  {
    image: require('../images/brainlyLogo.png'),
    width:'160px'
  },
  
  {
    image: require('../images/digitalLogo.png'),
    width:'200px'
  },
];

const Solutions = [
  {
    heading: 'STEM-Focused Learning Solutions',
    name: 'Accelerating Student Learning',
    content:
      'We provide tools and strategies that help students quickly grasp and excel in STEM subjects. Our solutions focus on making complex concepts in science, technology, engineering, and math more accessible and engaging, helping students learn more effectively and retain knowledge longer.',
    image: require('../images/acceleratingImage.png'),
    padding: 8,
  },
  {
    heading: '',
    name: 'Instructional Resources for Teachers',
    content:
      'We provide teachers with easy-to-follow instructional guides that help them plan and deliver their lessons more effectively. These guides offer practical advice, strategies, and tips to help teachers engage their students and make learning more meaningful.',
    image: require('../images/instructionalLearning.png'),
    padding: 14,
  },
  {
    heading: '',
    name: 'Teacher Resources and Lesson Plans',
    content:
      'We offer a wide variety of resources and ready-made lesson plans that help teachers prepare for their classes. Our materials include detailed lesson plans, worksheets, and activities that make it easier for teachers to deliver lessons, saving them time and effort in planning.',
    image: require('../images/teacherResource.png'),
    padding: 14,
  },
  {
    heading: '',
    name: 'STEM Game-Based Learning',
    content:
      'We develop fun and interactive math and science games and puzzles that help students enjoyably practice STEM skills. These games are designed to make learning more interesting, while also improving problem-solving and critical thinking abilities.',
    image: require('../images/GameBasedLearningImage.png'),
    padding: 14,
  },
];

const Home = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/letsconnect');
  };

  const handleServiceClick = route => {
    navigate(route);
  };
  const [isInView, setIsInView] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      { threshold: 0.7 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);
  const [isDesktop, setIsDesktop] = useState(false);

  console.log(isDesktop);
  return (
    <Box width={'100%'} borderColor="black" overflow="none">
      <Header />

      <Box background="linear-gradient( to left, rgba(131, 101, 142, 0.32) 10%, white 80%)">
        <Flex
          flexDirection={{
            xl: 'row',
            lg: 'row',
            md: 'row',
            base: 'column-reverse',
          }}
          flexWrap={'wrap'}
          alignItems={'center'}
          justifyContent={'space-around'}
          px={'4%'}
        >
          <motion.Box
            // // ref={ref}
            // // variants={isDesktop ? fadeIn('right', 0.2) : undefined}
            // // initial={isDesktop ? "hidden" : undefined}
            // // whileInView={isDesktop ? "show" : undefined}
            // // exit={isDesktop ? "exit" : undefined}
            // // viewport={{ once: false, amount: 0.7 }}
            // // animate={isDesktop && isInView ? 'show' : undefined}
            // ref={ref}
            // variants={isDesktop ? fadeIn('right', 0.2) : undefined}
            // initial={isDesktop ? 'hidden' : undefined}
            // whileInView={isDesktop ? 'show' : undefined}
            // exit={isDesktop ? 'exit' : undefined}
            // viewport={{ once: false, amount: 0.7 }}
            // animate={isDesktop && isInView ? 'show' : undefined}
            ref={ref}
            variants={fadeIn('right', 0.7)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            animate={isInView ? 'show' : 'exit'}
          >
            <AnimatedText
              fontFamily={'abhaya-libre-extrabold'}
              fontWeight={700}
              fontSize={{ xl: 48, lg: 48, md: 48, base: 42 }}
              color={'#DB3D3D'}
              marginTop={100}
            >
              Education is an ornament
            </AnimatedText>
            <AnimatedText
              fontFamily={'abhaya-libre-extrabold'}
              fontWeight={700}
              fontSize={{ xl: 48, lg: 48, md: 48, base: 42 }}
              color={'#5780DD'}
            >
              in prosperity and a refuge
            </AnimatedText>
            <AnimatedText
              fontFamily={'abhaya-libre-extrabold'}
              fontWeight={700}
              fontSize={{ xl: 48, lg: 48, md: 48, base: 42 }}
              color={'#077660'}
            >
              in adversity.
            </AnimatedText>
            <AnimatedText
              fontFamily={'arvo-regular'}
              fontSize={20}
              width={{ xl: 450, lg: 450, md: 400, sm: 380, base: '100%' }}
              fontWeight={500}
            >
              Welcome to{' '}
              <Text as="span" color={'darkblue'} fontWeight={700}>
                Vidyaa Tech,{' '}
              </Text>
              a premier EdTech company dedicated to transforming the educational
              landscape through innovative and comprehensive solutions.
            </AnimatedText>
          </motion.Box>

          <Box>
            <Image
              display={'flex'}
              position={'absolute'}
              right={0}
              top={{ xl: '5%', lg: '3%', md: '10%', sm: '3%', base: '2%' }}
              justifyContent={'right'}
              src={require('../images/threeColor.png')}
              width={250}
              height={12}
            />
            <motion.div
              ref={ref}
              variants={fadeIn('left', 0.7)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              animate={isInView ? 'show' : 'exit'}
            >
              <Image
                marginTop={100}
                resize={'contain'}
                width={{ xl: 400, lg: 370, md: 300, base: 280 }}
                height={{ xl: 490, lg: 450, md: 380, base: 350 }}
                src={require('../images/homeLogo.png')}
                // ref={imageRef}
              />
            </motion.div>
          </Box>
        </Flex>
        <Box>
          <Image
            src={require('../images/Group 331.png')}
            marginTop={150}
            width={250}
            height={12}
          />
        </Box>
        <MotionBox
          ref={ref}
          variants={fadeIn('up', 0.2)}
          initial="hidden"
          whileInView={'show'}
          viewport={{ once: false, amount: 0.7 }}
          animate={isInView ? 'show' : 'exit'}
          display={'flex'}
          flexDirection={'column'}
          textAlign={'center'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box
            sx={{ textShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)' }}
            margin={10}
            fontSize={38}
            fontFamily={'abhaya-libre-extrabold'}
            fontWeight={500}
          >
            About Us
          </Box>
          <Box
            textAlign={'left'}
            margin={10}
            // fontWeight={700}
            fontFamily={'Arvo-Regular'}
          >
            <Text>
              At{' '}
              <Text color={'red'} fontWeight={700} as="span">
                Vidyaa Tech,{' '}
              </Text>
              we're revolutionizing education with our exceptional e-learning
              services and solutions designed to meet the
            </Text>
            <Text>
              {' '}
              diverse needs of today's learners. Our platform leverages
              cutting-edge technology to deliver a seamless, interactive
              learning experience{' '}
            </Text>
            <Text>
              {' '}
              that adapts to individual learning styles and preferences. With
              real-time feedback, engaging multimedia content, and personalized
            </Text>
            <Text>
              {' '}
              learning pathways. Our solutions aim to create engaging and
              interactive learning environments that cater to various learning
              styles and needs.
            </Text>
            <Text marginTop={5}>
              {' '}
              Our solutions often facilitate global access to high-quality
              education, breaking down geographical barriers and providing
              opportunities for{' '}
            </Text>
            learners in diverse locations. This global reach is particularly
            valuable in expanding educational access to underserved or remote
            areas.
          </Box>
        </MotionBox>

        <Box position={'relative'}>
          <Box
            width={{ base: '80%', lg: '72%', xl: '50%' }}
            marginX={{ xl: 450, lg: 55, base: 10 }}
            marginY={10}
            color={'#317EBA'}
            // fontWeight={'600'}
            fontSize={24}
            fontFamily={'arvo-regular'}
          >
            Transform traditional education into a dynamic and immersive
            experience with Vidyaa Tech.
          </Box>

          <Image
            display={'flex'}
            position={'absolute'}
            right={0}
            bottom={{ lg: 10 }}
            justifyContent={'right'}
            src={require('../images/Group 148.png')}
            width={250}
            height={12}
          />
        </Box>
      </Box>

      <Flex
        className="services-section"
        flexDirection={{ lg: 'row', base: 'column' }}
        marginX={20}
        justifyContent={'space-around'}
      >
        <MotionBox
          ref={ref}
          variants={fadeIn('right', 0.9)}
          initial="hidden"
          whileInView={'show'}
          viewport={{ once: false, amount: 0.7 }}
          animate={isInView ? 'show' : 'exit'}
          textAlign={'Center'}
        >
          <Flex
            flexDirection={{ xl: 'column', lg: 'column', base: 'row' }}
            // textAlign={{xl:'left', lg:'center', base:'center'}}
            textAlign={'center'}
            alignItems={'center'}
            justifyContent={'center'}
            // width={{ xl: 190, base: 300 }}
            width={'100%'}
            fontSize={{ lg: 38, base: 30 }}
            fontWeight={700}
            sx={{ textShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)' }}
            marginY={{ lg: 20, base: 10 }}
            fontFamily={'Abhaya Libre ExtraBold'}
          >
            <Text
              fontFamily={'abhaya-libre-extrabold'}
              textAlign={'center'}
              marginRight={{ xl: 65, base: 3 }}
              className="services-text"
            >
              Our
            </Text>
            <Text
              fontFamily={'abhaya-libre-extrabold'}
              as={'span'}
              className="services-text"
            >
              Services
            </Text>
          </Flex>
          <Text
            // whiteSpace={'pre-line'}
            fontStyle={'italic'}
            color={' #BF1E3D'}
            // fontFamily={'noto-serif-semiBoldItalic'}
            fontWeight={500}
            fontSize={20}
            // marginTop={{ xl: 170 }}
            marginBottom={{ base: 10 }}
            width={{ xl: 350 }}
          >
            <Text className="services-text">“Unlock the future with</Text>
            <Text className="services-text"> premier learning services</Text>
            <Text className="services-text">
              {' '}
              where quality education pave the
            </Text>
            <Text className="services-text">
              {' '}
              path to extraordinary achievement.”
            </Text>
          </Text>
        </MotionBox>
        <motion.div
          ref={ref}
          variants={fadeIn('left', 0.7)}
          initial="hidden"
          whileInView={'show'}
          viewport={{ once: false, amount: 0.3 }}
          animate={isInView ? 'show' : 'exit'}
        >
          <div>
            <Grid
              margin={'auto'}
              // templateColumns="repeat(4, 1fr)"
              templateColumns={{
                base: 'repeat(1, 1fr)', // 1 column on small screens
                md: 'repeat(2, 1fr)',
                lg: 'repeat(2, 1fr)', // 2 columns on medium screens
                xl: 'repeat(4, 1fr)', // 3 columns on large screens
              }}
              // gap={4}
              px={{ xl: 4, md: 9, base: 0 }}
            >
              {Services.slice(0, 4).map((item, index) => {
                return (
                  <GridItem key={index}>
                    <Image
                      onClick={() => handleServiceClick(item.navigator)}
                      cursor="pointer"
                      _hover={{
                        transform: 'scale(1.1)',
                        transition: 'all 0.2s ease-in-out',
                      }}
                      height={{ xl: 180, lg: 170, md: 150, base: 150 }}
                      width={{ xl: 199, lg: 180, md: 160, base: 200 }}
                      src={item.image}
                      alt={item.name}
                      // objectFit="cover"
                      borderRadius="md"
                      m={{ xl: 5, base: 5 }}
                      mx={{ md: 20 }}
                    />
                  </GridItem>
                );
              })}
            </Grid>

            <Grid
              margin={'auto'}
              // templateColumns="repeat(3, 1fr)"
              templateColumns={{
                base: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
                lg: 'repeat(2, 1fr)',
                xl: 'repeat(3, 1fr)',
              }}
              px={{ xl: 20, md: 9, base: 0 }}
            >
              {Services.slice(4, 7).map((item, index) => {
                return (
                  <GridItem key={index}>
                    <Image
                      onClick={() => handleServiceClick(item.navigator)}
                      cursor="pointer"
                      _hover={{
                        transform: 'scale(1.1)',
                        transition: 'all 0.2s ease-in-out',
                      }}
                      height={{ xl: 180, lg: 170, md: 150, base: 150 }}
                      width={{ xl: 199, lg: 180, md: 160, base: 200 }}
                      src={item.image}
                      alt={item.name}
                      // objectFit="cover"
                      borderRadius="md"
                      m={{ xl: 5, base: 5 }}
                      mx={{ md: 20 }}
                    />
                  </GridItem>
                );
              })}
            </Grid>
          </div>
        </motion.div>
      </Flex>
      <Box
        className="pride-section"
        background="linear-gradient( to left, rgba(131, 100, 142, 0.6) 50%, white 100%)"
        // height={500}
      >
        <Text
          fontFamily={'abhaya-libre-extrabold'}
          fontSize={{ xl: 38, base: 28 }}
          fontWeight={800}
          sx={{
            textShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)',
          }} /* 0.25 is 25% opacity */
          textAlign={'center'}
          padding={10}
        >
          Our Determination is our Pride{' '}
        </Text>
        <Flex
          padding={1.5}
          flexWrap={'wrap'}
          pb={20}
          gap={6}
          justifyContent={'center'}
          // ref={prideRef}
        >
          {Determination.map((item, index) => {
            return (
              <MotionBox
                ref={ref}
                variants={fadeIn('up', 0.2)}
                initial="hidden"
                whileInView={'show'}
                viewport={{ once: false, amount: 0.7 }}
                animate={isInView ? 'show' : 'exit'}
                position={'relative'}
              >
                <Image
                  // ref={el => boxesRef.current[index] = el}
                  key={index}
                  margin={{ lg: 10, md: 10 }}
                  height={{ xl: 420, md: 600, base: 400, lg: 600 }}
                  width={{ xl: 400, base: 600, lg: 600 }}
                  src={require('../images/bgFrame.png')}
                />
                <Box
                  position="absolute"
                  top={{ base: '20%', md: '22%' }}
                  backdropFilter={10}
                  fontWeight={600}
                  alignItems={'center'}
                  justifyContent={'center'}
                  width={'100%'}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <Text
                    fontSize={{ xl: 20, base: 16, md: 32 }}
                    marginTop={{ xl: 8, base: 2 }}
                    // marginLeft={{lg:109}}
                    fontWeight={800}
                    textAlign={'center'}
                    // marginLeft={{lg:'72%'}}
                    fontFamily={'abhaya-libre-extrabold'}
                    color={'#B72323'}
                  >
                    {item.heading}
                  </Text>
                  <Text
                    fontSize={{ xl: 13, base: 12, md: 19 }}
                    ml={2}
                    padding={3}
                    width={{ lg: '50%', md: '55%', base: '71%' }}
                    textAlign={'left'}
                    fontFamily={'arvo-regular'}
                  >
                    {item.text}
                  </Text>
                </Box>
              </MotionBox>
            );
          })}
        </Flex>
      </Box>
      {Solutions.map((solution, index) => (
        <LearningSolution
          key={index}
          index={index}
          heading={solution.heading}
          padding={solution.padding}
          text={solution.name}
          content={solution.content}
          image={solution.image}
        />
      ))}

      <Box position={'relative'}>
        <Box
          padding={10}
          height={'max-content'}
          background="linear-gradient( to left,rgba(213,205,221,255) 30%, rgba(235,219,227,255) 50%)"
        >
          <Text
            fontFamily={'abhaya-libre-extrabold'}
            fontWeight={800}
            fontSize={{ xl: 38, base: 28 }}
            position={'relative'}
            textAlign={{ xl: 'right', base: 'center' }}
            marginRight={{ xl: 380 }}
            sx={{ textShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)' }}
          >
            Our Trusted Partners
          </Text>
          {/* <Flex justifyContent={'space-between'} alignContent={'center'}> */}
          <Box>
            <Grid
              marginLeft={{ xl: '30%' }}
              // marginLeft={{md:'10%'}}
              // width={'70%'}
              marginRight={{ xl: 140 }}
              marginTop={10}
              templateColumns={{
                xl: 'repeat(3, 1fr)',
                md: 'repeat(3, 1fr)',
                base: 'repeat(2, 1fr)',
              }} // 3 equal columns
              // templateRows="repeat(4, 1fr)" // 4 equal rows
              gap={9} // Space between grid items
            >
              {Partners.map((item, index) => (
                <motion.div
                  ref={ref}
                  variants={fadeIn('up', 0.2)}
                  initial="hidden"
                  whileInView={'show'}
                  viewport={{ once: false, amount: 0.7 }}
                  animate={isInView ? 'show' : 'exit'}
                >
                  <Image
                    cursor={'pointer'}
                    marginX={{ xl: 20, md: 14 }}
                    key={index}
                    width={item.width}
                    src={item.image}
                  />
                </motion.div>
              ))}
            </Grid>
          </Box>
          {/* </Flex> */}
        </Box>
        <Box
          zIndex={10}
          border={4}
          borderColor={'#35356F'}
          padding={20}
          background="conic-gradient(from 35.64deg at -31.94% 88.73%, #F8F8F8 0deg, #F9F9F9 0.04deg, rgba(131, 101, 142, 0.82) 48.6deg, #EDCFED 66.73deg, #CBA9A9 75.6deg, #FFD1D1 75.64deg, #EFFFFF 140.4deg, #F9F9F9 310.43deg, #F8F8F8 360deg)"
        >
          <Text
            fontFamily={'abhaya-libre-extrabold'}
            fontSize={35}
            fontWeight={800}
            color={{ xl: 'white' }}
            display={{ xl: 'block', base: 'none' }}
            marginLeft={{ xl: 550 }}
            sx={{ textShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)' }}
            // css={{
            //   WebkitTextStroke: '.5px #7C7878', // Outline color and width (red)
            // }}
          >
            Blog
          </Text>
          <Flex
            justifyContent={'space-around'}
            flexDirection={{ xl: 'row', md: 'row', base: 'column' }}
          >
            <Image
              marginLeft={{ xl: 450 }}
              padding={5}
              borderTopRightRadius="50"
              borderBottomLeftRadius="50"
              // rounded={10}
              height={{ xl: 350 }}
              width={{ xl: 440, lg: 350, md: 280 }}
              display={{ xl: 'block', lg: 'block', md: 'block', base: 'none' }}
              // border={1}
              src={require('../images/contact.png')}
            />

            <Box>
              <Flex
                sx={{ textShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)' }}
                fontFamily={'abhaya-libre-extrabold'}
                fontWeight={800}
                fontSize={{ xl: 35, base: 25 }}
                textAlign={{ base: 'center' }}
                marginTop={{ base: 5, md: 20 }}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={{ xl: 'column' }}
              >
                <Text marginRight={{ base: 2 }}>Work </Text>
                <Text
                  marginBottom={{ xl: 20 }}
                  sx={{ textShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)' }}
                  fontFamily={'abhaya-libre-extrabold'}
                  fontWeight={800}
                  fontSize={{ xl: 35, base: 25 }}
                  textAlign={{ base: 'center' }}
                >
                  with us!
                </Text>
              </Flex>
              <Image
                cursor={'pointer'}
                height={{ xl: 58, base: 10 }}
                width={{ xl: 197 }}
                marginY={{ base: 5 }}
                marginX={{ base: 'auto' }}
                _hover={{
                  transform: 'scale(1.05)',
                  transition: 'all 0.2s ease-in-out',
                }}
                src={require('../images/letsConnectBlue.png')}
                onClick={handleClick}
              />
            </Box>
          </Flex>
        </Box>
        <Box>
          <Image
            left={0}
            top={'11.5%'}
            height={800}
            position={'absolute'}
            display={{ xl: 'Block', base: 'none' }}
            src={require('../images/girl.png')}
          />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default Home;
//#e5d4de
