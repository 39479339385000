import { Box, Flex, GridItem,Grid, Image, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
const DropDownServices = () => {
  const navigate = useNavigate();
  const services = [
    {
      image: require('../images/learningServ.png'),
      navigate: '/services/learning'
    },
    
    {
      image: require('../images/editorialServ.png'),
      navigate: '/services/editorial'
    },
    
    {
      image: require('../images/alignmentServ.png'),
      navigate: '/services/alignment'
    },
    {
      image: require('../images/animationServ.png'),
      navigate: '/services/animation'
    },
    {
      image: require('../images/authoringServ.png'),
      navigate: '/services/authoring'
    },
    {
      image: require('../images/tutoringServ.png'),
      navigate: '/services/tutoring'
    },
    {
      image: require('../images/translationSev.png'),
      navigate: '/services/translation'
    },
  ];
  return (
    <Box
      position="absolute"
  bgGradient="linear(to-l, rgba(115, 80, 96, 1) 10%, rgba(46, 48, 110, 1) 90%)"
      p={{xl:8}}
      fontSize={15}
      width={{ xl:1450, lg: 250, md: 250, sm: 220, base: 160 }}
      ml={{xl:'-978%', lg:'-9%', md:'-980%', sm:'-'}}
      boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
      borderRadius="4px"
      zIndex={1}
    >
      <Flex flexWrap={'wrap'} justifyContent={'space-around'}>
        {services.map((service, index) => (
          <Box
            onClick={e => {
              e.stopPropagation();
              navigate(service.navigate);
            }}
            m={2}
            key={index} 
            height="60px"
            borderRadius="10px"
            width="280px"
            bgGradient="radial-gradient(circle, rgba(239, 241, 255, 1) 0%, rgba(211, 185, 211, 1) 100%)"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Image margin={5} p={{xl:1, lg:0, md:0, base:2}} src={service.image} alt="service" height="50px" />
          </Box>
        ))}
      </Flex>
      {/* <Grid width={{ xl:'70%',}}  templateColumns={{
                base: 'repeat(3, 1fr)', // 1 column on small screens
                // md: 'repeat(2, 1fr)',
                // lg: 'repeat(2, 1fr)',
                // xl: 'repeat(4, 1fr)',
              }}>
  {services?.slice(0, 3).map((service) => (
    <GridItem
      key={service.id} 
      onClick={(e) => {
        e.stopPropagation();
        navigate(service.navigate);
      }}
      m={2}
      height="60px"
      borderRadius="10px"
      width="280px"
      mx={{20}}
      bgGradient="radial-gradient(circle, rgba(239, 241, 255, 1) 0%, rgba(211, 185, 211, 1) 100%)"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Image
        margin={5}
        p={{ base: 2, xl: 1 }}
        src={service.image}
        alt="service"
        height="50px"
      />
    </GridItem>
  ))}
</Grid>
<Grid margin={'auto'} templateColumns={{
                base: 'repeat(1, 1fr)', // 1 column on small screens
                md: 'repeat(2, 1fr)',
                lg: 'repeat(2, 1fr)', // 2 columns on medium screens
                xl: 'repeat(4, 1fr)', // 3 columns on large screens
              }}>
  {services?.slice(3, 7).map((service) => (
    <GridItem
      key={service.id} 
      onClick={(e) => {
        e.stopPropagation();
        navigate(service.navigate);
      }}
      m={2}
      height="60px"
      borderRadius="10px"
      width="280px"
      bgGradient="radial-gradient(circle, rgba(239, 241, 255, 1) 0%, rgba(211, 185, 211, 1) 100%)"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Image
        margin={5}
        p={{ base: 2, xl: 1 }}
        src={service.image}
        alt="service"
        height="50px"
      />
    </GridItem>
  ))}
</Grid> */}

    </Box>
  );
};
export default DropDownServices;
{
  /* <ul
style={{ listStyle: 'none', padding: 0, margin: 0 }}
color="rgba(18, 18, 155, 1)"
>
<li
  style={{
    marginBottom: 5,
    padding: '5px 0',
    cursor: 'pointer',
  }}
  onClick={e => {
    e.stopPropagation();
    navigate('/services/learning');
  }}
>
  E-learning Customization
</li>
<li
  style={{
    marginBottom: 5,
    padding: '5px 0',
    cursor: 'pointer',
  }}
  onClick={e => {
    e.stopPropagation();
    navigate('/services/editorial');
  }}
>
  Editorial Services
</li>
<li
  style={{
    marginBottom: 5,
    padding: '5px 0',
    cursor: 'pointer',
  }}
  onClick={e => {
    e.stopPropagation();
    navigate('/services/tutoring');
  }}
>
  Tutoring
</li>
<li
  style={{
    marginBottom: 5,
    padding: '5px 0',
    cursor: 'pointer',
  }}
  onClick={e => {
    e.stopPropagation();
    navigate('/services/authoring');
  }}
>
  Authoring Services
</li>
<li
  style={{
    marginBottom: 5,
    padding: '5px 0',
    cursor: 'pointer',
  }}
  onClick={e => {
    e.stopPropagation();
    navigate('/services/alignment');
  }}
>
  Alignment Services
</li>
<li
  style={{
    marginBottom: 5,
    padding: '5px 0',
    cursor: 'pointer',
  }}
  onClick={e => {
    e.stopPropagation();
    navigate('/services/translation');
  }}
>
  Translation Services
</li>
<li
  style={{
    marginBottom: 5,
    padding: '5px 0',
    cursor: 'pointer',
  }}
  onClick={e => {
    e.stopPropagation();
    navigate('/services/animation');
  }}
>
  Animation Services
</li>
</ul> */
}
