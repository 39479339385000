import { Box, Flex, Grid, Image, Text } from '@chakra-ui/react';
import { useState } from 'react';
import PopUpModale from './PopUpModale';
const AnimationPageBoxes = ({ image }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <Box>
      <Image
        // _hover={{
        //   transform: "scale(1.1)", // Slightly increased scaling for more pronounced zoom
        //   boxShadow: "0 4px 20px rgba(0, 0, 0, 0.5)", // Stronger shadow with blur
        //   filter: "brightness(1.1)", // Slightly increase brightness for more vibrant effect
        //   opacity: 0.9, // Add a subtle opacity change
        //   transition: "transform 0.4s ease, box-shadow 0.4s ease, filter 0.3s ease, opacity 0.3s ease"
        // }}
        margin={8}
        width={{ xl: '320px', md: '300px', sm: '350px', base: '500px' }}
        cursor={'pointer'}
        height={{ xl: '370px', base: '350px' }}
        alt=""
        src={image.image}
        onClick={() => setShowModal(true)}
      />
      {showModal && (
        <PopUpModale text={image.text} onClose={() => setShowModal(false)} />
      )}
    </Box>
  );
};
export default AnimationPageBoxes;
