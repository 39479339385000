import Header from './Header';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import ViewAllServices from './ViewAllServices';
import Footer from './Footer';
import { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../varients';
const ELearning = () => {
  const [isInView, setIsInView] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      { threshold: 0.7 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  // ref={ref}
  //         variants={fadeIn('up', 0.7)}
  //         initial="hidden"
  //         whileInView={'show'}
  //         viewport={{ once: false, amount: 0.3 }}
  //         animate={isInView ? 'show' : 'exit'}
  return (
    <Box background="linear-gradient(to left, rgba(131, 101, 142, 0.32) 10%, white 80%)">
      <Header />
      <motion.div
        ref={ref}
        variants={fadeIn('up', 0.9)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
        animate={isInView ? 'show' : 'exit'}
      >
        <Box p={'3%'}>
          <Flex
            flexDirection={{ xl: 'row', md: 'row', base: 'column-reverse' }}
            alignItems={'center'}
            justifyContent={'space-around'}
          >
            <Box>
              <Flex
                alignItems={'center'}
                flexDirection={{
                  xl: 'row',
                  lg: 'row',
                  md: 'row',
                  sm: 'column',
                  base: 'column',
                }}
              >
                <Image height={20} src={require('../images/elearnImg.png')} />
                <Text
                  textAlign={{ xl: 'left', md: 'left', base: 'center' }}
                  mb={3}
                  color={'#060668'}
                  sx={{ textShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)' }}
                  fontSize={31}
                  fontWeight={800}
                  fontFamily={'abhaya-libre-extrabold'}
                >
                  E-Learning Customization Services
                </Text>
              </Flex>
              <Text fontFamily={'arvo-regular'} fontSize={{ xl: 20 }}>
                <Text as={'span'} fontWeight={700} color={'#B72323'}>
                  Vidyaa-Tech
                </Text>
                <Text as={'span'}> </Text>
                provides E-Learning Customization Services referring to a range
                of specialized solutions<Text as={'span'}> </Text>
                {/* <Text> */}
                designed to adapt and tailor digital learning experiences to
                meet the specific needs of learners. {/* </Text> */}
                {/* <Text> */}
                These services aim to transform traditional educational methods
                into dynamic, engaging, and {/* </Text> */}
                effective online learning environments.
              </Text>
            </Box>
          </Flex>
        </Box>
      </motion.div>

      <motion.div
        ref={ref}
        variants={fadeIn('up', 0.9)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
        animate={isInView ? 'show' : 'exit'}
      >
        <Box
          mt={{ xl: 0, lg: -5, base: 5 }}
          width={'97%'}
          ml={{ xl: '2%', lg: '3%', md: '3%', base: '4%' }}
          fontFamily={'arvo-regular'}
          fontSize={{ xl: 19, base: 17 }}
        >
          <Text display={'flex'} flexDirection={{ xl: 'row', base: 'column' }}>
            <Flex alignItems={'center'}>
              <Image mr={2} height={3} src={require('../images/blueDot.png')} />
              <Text
                fontWeight={700}
                fontSize={{ xl: 20, base: 17 }}
                color={'#3263CC'}
                as={'span'}
              >
                Custom Assessment Development :
              </Text>{' '}
            </Flex>
            <Text>
              Our Assessment Services offer invaluable insights into students'
              strengths and weaknesses.
            </Text>
          </Text>{' '}
          <Text ml={{ xl: 7 }}>
            Well-designed assessments enhance student engagement through
            interactive and challenging learning experiences.
          </Text>
        </Box>
      </motion.div>

      <Box
        overflowX="auto"
        sx={{
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Box
          display="inline-block"
          width={{ xl: 1800, lg: 1400, md: 1300, base: 1000 }}
        >
          <Image
            // align="center"
            p={{ xl: 8, base: '2' }}
            // marginX={'auto'}
            mx={{ xl: 3, lg: 7, md: 5, base: 3 }}
            my={5}
            width={{ xl: '65%', base: '80%' }}
            src={require('../images/table3.png')}
            alt="Table Image"
          />
        </Box>
      </Box>

      <Box
        overflowX="auto"
        sx={{
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Box
          display="inline-block"
          width={{ xl: 1800, lg: 1400, md: 1300, base: 1000 }}
        >
          <Image
            align="center"
            p={{ xl: 8, base: '2' }}
            // marginX={'auto'}
            mx={{ xl: 3, lg: 7, md: 5, base: 4 }}
            width={{ xl: '65%', base: '80%' }}
            src={require('../images/table2.png')}
            alt="Table Image"
          />
        </Box>
      </Box>

      <Box
        overflowX="auto"
        sx={{
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Box
          display="inline-block"
          width={{ xl: 1800, lg: 1300, md: 1300, base: 1000 }}
        >
          <Image
            align="center"
            // p={{xl:8, base:'2'}}
            // marginX={'auto'}
            mx={{ xl: 10, lg: 8, md: 5, base: 5 }}
            width={{ xl: '65%', base: '80%' }}
            src={require('../images/table1.png')}
            alt="Table Image"
          />
        </Box>
      </Box>

      <Flex
        p={{ xl: 8, lg: 7, md: 5, base: '2' }}
        justifyContent={'space-around'}
      >
        <Box
          width={'100%'}
          fontFamily={'arvo-regular'}
          fontSize={{ xl: 19, lg: 18, md: 17, base: 15 }}
        >
          <Text display={'flex'} flexDirection={{ xl: 'row', base: 'column' }}>
            <Flex alignItems={'center'}>
              <Image mr={2} height={3} src={require('../images/blueDot.png')} />
              <Text fontWeight={700} color={'#3263CC'} as={'span'}>
                Custom Curriculum Development :{' '}
              </Text>{' '}
            </Flex>
          </Text>
        </Box>
      </Flex>

      <Box
        fontFamily={'arvo-regular'}
        width={'85%'}
        fontSize={{ xl: 19, lg: 17, md: 16, base: 15 }}
        mx={{ xl: '5%', lg: '3%', md: '3%', base: '2%' }}
      >
        <motion.div
          ref={ref}
          variants={fadeIn('up', 0.9)}
          initial="hidden"
          whileInView={'show'}
          viewport={{ once: false, amount: 0.3 }}
          animate={isInView ? 'show' : 'exit'}
        >
          <Box mb={5} mt={-3}>
            <Text>
              At Vidyaa Tech, we specialize in creating tailored curricula that
              align with a variety of educational standards, including 
              <Text as={'span'} fontWeight={'bold'}>
                GCSE, IGCSE, CBSE, ICSE, US Curriculum,
              </Text>{' '}
              and more. Our expert team works closely with educators and
              professionals to ensure the curriculum is not only effective and
              engaging but also adaptable to changing educational trends and the
              evolving needs of learners. We focus on fostering a dynamic
              learning environment that meets academic goals while preparing
              students for future challenges.
              {/* 
 Our approach includes: 
Needs Analysis: We conduct thorough research to understand client objectives and identify the specific needs of the target audience.
Curriculum Design: Collaborating with experts, we define learning objectives, sequence content, and choose effective instructional strategies.
Content Development: We create interactive, multimedia-rich learning materials that enhance engagement and reinforce curriculum goals.
Review and Revision: Through continuous feedback, we refine the curriculum to maintain relevance and ensure effectiveness. */}
            </Text>
          </Box>
        </motion.div>
        <motion.div
          ref={ref}
          variants={fadeIn('up', 0.9)}
          initial="hidden"
          whileInView={'show'}
          viewport={{ once: false, amount: 0.3 }}
          animate={isInView ? 'show' : 'exit'}
        >
          <Text fontWeight={'bold'}>Our approach includes:</Text>
          <Text fontSize={18} marginX={5}>
            <ul>
              <li>
                Needs Analysis: We conduct thorough research to understand
                client objectives and identify the specific needs of the target
                audience.
              </li>
              <li>
                Curriculum Design: Collaborating with experts, we define
                learning objectives, sequence content, and choose effective
                instructional strategies.
              </li>
              <li>
                Content Development: We create interactive, multimedia-rich
                learning materials that enhance engagement and reinforce
                curriculum goals
              </li>
              <li>
                Review and Revision: Through continuous feedback, we refine the
                curriculum to maintain relevance and ensure effectiveness.
              </li>
            </ul>
          </Text>
        </motion.div>
        <motion.div
          ref={ref}
          variants={fadeIn('up', 0.9)}
          initial="hidden"
          whileInView={'show'}
          viewport={{ once: false, amount: 0.3 }}
          animate={isInView ? 'show' : 'exit'}
        >
          <Flex
            margin={5}
            justifyContent={'space-around'}
            flexDirection={{ base: 'column', md: 'row', lg: 'row', xl: 'row' }}
          >
            <Image
              height={250}
              width={{ xl: 280, base: 250 }}
              marginX={{ base: 'auto', md: 3 }}
              alt=""
              src={require('../images/gcse.png')}
            />
            <Image
              height={250}
              width={{ xl: 280, base: 250 }}
              marginX={{ base: 'auto', md: 3 }}
              alt=""
              src={require('../images/igcseImg.png')}
            />
            <Image
              height={250}
              width={{ xl: 280, base: 250 }}
              marginX={{ base: 'auto', md: 3 }}
              alt=""
              src={require('../images/cbseImg.png')}
            />
          </Flex>
        </motion.div>
      </Box>
      <Flex
        p={{ xl: 8, lg: 7, md: 5, base: '2' }}
        justifyContent={'space-around'}
      >
        <Box
          width={'100%'}
          fontFamily={'arvo-regular'}
          fontSize={{ xl: 19, lg: 18, md: 17, base: 15 }}
        >
          <motion.div
            ref={ref}
            variants={fadeIn('up', 0.9)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            animate={isInView ? 'show' : 'exit'}
          >
            <Text
              display={'flex'}
              flexDirection={{ xl: 'row', base: 'column' }}
            >
              <Flex alignItems={'center'}>
                <Image
                  mr={2}
                  height={3}
                  src={require('../images/blueDot.png')}
                />
                <Text fontWeight={700} color={'#3263CC'} as={'span'}>
                  Custom Test Prepartion :{' '}
                </Text>{' '}
              </Flex>
            </Text>{' '}
            <Text ml={{ xl: 7 }}>
              Vidyaa Tech offers high-quality, customized test preparation
              materials for a wide range of standardized exams, including{' '}
              <Text as={'span'} fontWeight={'bold'}>
                 IIT, JEE,  NEET, SAT, ACT, CUET,
              </Text>{' '}
              and more. Our comprehensive approach ensures students are
              well-prepared for all aspects of their exams, setting you apart in
              a competitive e-learning market. Our services include:
            </Text>
          </motion.div>
          <motion.div
            ref={ref}
            variants={fadeIn('up', 0.9)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            animate={isInView ? 'show' : 'exit'}
          >
            <Box mx={'4%'} mt={5}>
              <ul>
                <li>Content Analysis</li>
                <li>Resource Development</li>
                <li>Feedback and Improvement</li>
                <li>Performance Monitoring</li>
              </ul>
            </Box>
          </motion.div>
          <motion.div
            ref={ref}
            variants={fadeIn('up', 0.9)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            animate={isInView ? 'show' : 'exit'}
          >
            <Flex
              margin={5}
              justifyContent={'space-around'}
              flexDirection={{
                base: 'column',
                md: 'row',
                lg: 'row',
                xl: 'row',
              }}
            >
              <Box textAlign={'center'}>
                <Image
                  border="2px solid black"
                  height={250}
                  width={{ xl: 280, base: 250 }}
                  margin={3}
                  marginX={{ base: 'auto', md: 3 }}
                  padding={8}
                  borderRadius={10}
                  alt=""
                  src={require('../images/iitImg.png')}
                />
                <Text fontWeight={'bold'}>IIT</Text>
              </Box>
              <Box textAlign={'center'}>
                <Image
                  border="2px solid black"
                  height={250}
                  width={{ xl: 280, base: 250 }}
                  margin={3}
                  marginX={{ base: 'auto', md: 3 }}
                  padding={8}
                  borderRadius={10}
                  alt=""
                  src={require('../images/neetImg.png')}
                />
                <Text fontWeight={'bold'}>NEET</Text>
              </Box>
              <Box textAlign={'center'}>
                <Image
                  border="2px solid black"
                  height={250}
                  width={{ xl: 280, base: 250 }}
                  margin={3}
                  marginX={{ base: 'auto', md: 3 }}
                  padding={8}
                  borderRadius={10}
                  alt=""
                  src={require('../images/satImg.png')}
                />
                <Text fontWeight={'bold'}>SAT</Text>
              </Box>
            </Flex>
          </motion.div>
        </Box>
      </Flex>

      <Flex p={{ xl: 8, lg: 7, md: 5, base: '2' }}>
        <Box
          fontFamily={'arvo-regular'}
          fontSize={{ xl: 19, lg: 18, md: 17, base: 15 }}
        >
          <motion.div
            ref={ref}
            variants={fadeIn('up', 0.9)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            animate={isInView ? 'show' : 'exit'}
          >
            <Text
              display={'flex'}
              flexDirection={{ xl: 'row', base: 'column' }}
            >
              <Flex alignItems={'center'}>
                <Image
                  mr={2}
                  height={3}
                  src={require('../images/blueDot.png')}
                />
                <Text fontWeight={700} color={'#3263CC'} as={'span'}>
                  Diversity, Equity, and Inclusion (DEI) Reviews -{' '}
                </Text>
              </Flex>
              We provide valuable services to you by offering comprehensive
              reviews and
            </Text>
            <Text ml={{ xl: 7 }}>
              {' '}
              assessments focused on Diversity, Equity, and Inclusion (DEI)
            </Text>
          </motion.div>
          <motion.div
            ref={ref}
            variants={fadeIn('up', 0.9)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            animate={isInView ? 'show' : 'exit'}
          >
            <Box ml={'3%'} mt={3}>
              <ul>
                <li>Initial Assessment</li>
                <li>Evaluation</li>
                <li>Implementation Support</li>
              </ul>
            </Box>
          </motion.div>
        </Box>
      </Flex>

      <Flex
        p={{ xl: 8, lg: 7, md: 5, base: '2' }}
        justifyContent={'space-around'}
      >
        <Box
          fontFamily={'arvo-regular'}
          fontSize={{ xl: 19, lg: 18, md: 17, base: 15 }}
        >
          <motion.div
            ref={ref}
            variants={fadeIn('up', 0.9)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            animate={isInView ? 'show' : 'exit'}
          >
            <Text
              display={'flex'}
              flexDirection={{ xl: 'row', base: 'column' }}
            >
              <Flex alignItems={'center'}>
                <Image
                  mr={2}
                  height={3}
                  src={require('../images/blueDot.png')}
                />
                <Text fontWeight={700} color={'#3263CC'} as={'span'}>
                  Course Customization -{' '}
                </Text>
              </Flex>
              We offer a comprehensive course customization service designed to
              empower educators and enhance the learning experience.
            </Text>

            <Text ml={{ xl: 7 }}>
              {' '}
              Our team works closely with educators to align course content with
              specific educational standards and objectives, ensuring that each
              course is relevant and effective. We integrate engaging multimedia
              elements, such as videos and interactive quizzes to keep students{' '}
              motivated and involved.
            </Text>
          </motion.div>
          <motion.div
            ref={ref}
            variants={fadeIn('up', 0.9)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            animate={isInView ? 'show' : 'exit'}
          >
            <Box ml={'3%'} mt={5}>
              <ul>
                <li>Initial Consultation</li>
                <li>Requirements Analysis</li>
                <li>Customization Plan Development</li>
                <li>Content Creation and Integration</li>
              </ul>
            </Box>
          </motion.div>
        </Box>
      </Flex>
      <motion.div
        ref={ref}
        variants={fadeIn('up', 0.9)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
        animate={isInView ? 'show' : 'exit'}
      >
        <ViewAllServices />
      </motion.div>
      <Footer />
    </Box>
  );
};
export default ELearning;
